<template>
  <div class="mb-4">
    <CContainer>
      <CRow class="align-items-end">
        <CCol md="6" class="mb-md-0 mb-4">
          <section-title
            tagline="OUR BRANDS"
            :title="title"
            :subtitle="sub_title"
          />
        </CCol>
        <CCol md="6">
          <swiper-nav
            leftClassName="brandPprevArrow"
            RightClassName="brandNextArrow"
          />
        </CCol>
      </CRow>
    </CContainer>
  </div>
  <CContainer>
    <CRow>
      <CCol md="12">
        <div class="pt-4">
          <swiper
            :modules="modules"
            :breakpoints="breakpoints"
            :autoHeight="false"
            :space-between="50"
            :navigation="{
              nextEl: '.brandNextArrow',
              prevEl: '.brandPprevArrow',
            }"
            :pagination="false"
            :scrollbar="{ draggable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide v-for="brand in brands" :key="brand.id">
              <router-link :to="'/brand/' + brand.id">
                <CCard class="p-4">
                  <img :src="brand.image" alt="" />
                </CCard>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { CRow, CCol, CContainer, CCard } from "@coreui/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import SwiperNav from "@/components/shared-components/swiper-nav/SwiperNav.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "Brands",
  components: {
    CRow,
    CCol,
    CContainer,
    CCard,
    Swiper,
    SwiperSlide,
    SectionTitle,
    SwiperNav,
  },

  data() {
    return {
      title: ref(""),
      sub_title: ref(""),
      brands: ref([]),

      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 10,
        },

        425: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        767: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
        1600: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
      },
    };
  },

  mounted() {
    this.getBrandSectionDetail();
    this.brandDetail();
  },

  methods: {
    async getBrandSectionDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-about-us-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let brandSectionData = response.data.data;
          if (response.data.status_code === 200 && brandSectionData !== "") {
            this.title = brandSectionData.brand_title;
            this.sub_title = brandSectionData.brand_description;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    async brandDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/brand-list";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let brandLists = response.data.data;
          if (response.data.status_code === 200 && brandLists.length > 0) {
            Object.entries(brandLists).forEach(([key, value]) => {
              this.brands.push({
                id: value.id,
                slug: value.title,
                image: value.image_path,
              });
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
