<template>
  <section class="home-video">
    <page-banner :image="bannerImage">
      <div class="col-md-5">
        <banner-title
          tagline="Who we are"
          :title="bannerTitle"
          :description="bannerDescription"
        />
        <div class="text-center mt-4">
          <button
            class="btn video-play-icon"
            @click="
              () => {
                visibleVerticallyCenteredDemo = true;
              }
            "
          >
            <img src="/img/playicon.png" alt="" />
          </button>
        </div>
      </div>
    </page-banner>
    <CModal
      alignment="center"
      :visible="visibleVerticallyCenteredDemo"
      @close="
        () => {
          visibleVerticallyCenteredDemo = false;
        }
      "
    >
      <CModalHeader>
        <CModalTitle>Video title</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <iframe
          class="modal-video"
          :src="bannerVideo"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </CModalBody>
    </CModal>
  </section>
</template>

<script>
import PageBanner from "@/components/shared-components/page-banner/PageBanner.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";


export default {
  name: "HomeVideo",
  components: { PageBanner, BannerTitle },

  props: [
    "bannerTitle",
    "bannerSubTitle",
    "bannerImage",
    "bannerVideo",
    "bannerDescription",
  ],

  data() {
    return {
      visibleVerticallyCenteredDemo: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
