<template>
  <section class="rider-stories py-5">
    <div
      class="bg-image"
      :style="{ 'background-image': `url('${image}')` }"
    ></div>
    <CContainer>
      <CRow>
        <CCol md="6"> </CCol>
        <CCol md="6">
          <section-title
            tagline="RIDERS STORIES"
            :title="title"
            :subtitle="subtitle"
          />

          <div class="pt-4">
            <swiper
              :modules="modules"
              :breakpoints="swiperOptions"
              :autoHeight="false"
              :slides-per-view="1"
              :space-between="50"
              :navigation="{
                nextEl: '.riderNextArrow',
                prevEl: '.RiderPprevArrow',
              }"
              :pagination="{ clickable: true }"
              :scrollbar="{ draggable: true }"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
              <swiper-slide v-for="rider in riders" :key="rider.id">
                <rider-card :rider="rider" />
              </swiper-slide>
            </swiper>
            <swiper-nav
              leftClassName="RiderPprevArrow"
              RightClassName="riderNextArrow"
            />
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>


import { CRow, CCol, CContainer } from "@coreui/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import RiderCard from "@/components/shared-components/rider-card/RiderCard.vue";
import SwiperNav from "@/components/shared-components/swiper-nav/SwiperNav.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {ref} from "vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "RiderStories",
  components: {
    CRow,
    CCol,
    CContainer,
    Swiper,
    SwiperSlide,
    SectionTitle,
    RiderCard,
    SwiperNav,
  },
  props:['title','subtitle','description','image'],

  data() {
    return {
      riders: ref([])
    };
  },

  mounted() {
    this.ridersDetail();
  },

  methods: {
    async ridersDetail(){
      const url = UrlConfig.urlHelper.hostUrl +'/get-rider-story-list' ;
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios.get(url,header)
          .then(response => {
            let riderLists = response.data.data;
            if(response.data.status_code === 200 && riderLists.length > 0){
              Object.entries(riderLists).forEach(([key, value]) => {
                this.riders.push({
                  id: value.id,
                  name: value.name,
                  riderTestimonial: value.quote,
                  image: value.image_path,
                });
              });
            }
          }).catch((error) => {
            toast.error(error.response.data.message, {
              timeout: 2000,
            });
      });
    }


  },


};
</script>

<style lang="scss" scoped></style>
